// Form validation style of es-tags-input custom web component
@mixin form-validation-tags-input($state, $color) {
    es-tags-input {
        .was-validated &[internals-#{$state}],
        .was-validated &:#{$state},
        .was-validated &.is-#{$state}
         {

        
            border-color: $color;
        
            &:focus {
              border-color: $color;
              box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
            }
      
            
          

            ~ .#{$state}-feedback,
            ~ .#{$state}-tooltip {
            display: block;
            }
        }
      }
}

@include form-validation-tags-input("valid", $form-feedback-valid-color);
@include form-validation-tags-input("invalid", $form-feedback-invalid-color);

es-tags-input {
  min-height: 35px;
  display: block;
}
.es_autocomplete-body {
    border: 1px solid $border-color;
    background: $white;
    z-index: $zindex-tooltip;
    overflow: auto;

    .es_autocomplete-loading {
        padding: 1rem;
    }

    .es_autocomplete-list {
        list-style: none;
        padding: 0;
        margin: 0;

        .es_autocomplete-option-group {
            padding: $input-padding-y $input-padding-x;
            position: relative;
            > span {
                font-weight: bold;
            }
            ul {
                list-style: none;
                padding: 0;
            }
        }

        .es_autocomplete-option {
            padding: $input-padding-y $input-padding-x;
            position: relative;

            &:not(:last-child) {
                &::after {
                    background: $border-color;
                    content: "";
                    display: block;
                    height: 1px;
                    left: 0.75rem;
                    position: absolute;
                    right: 0.75rem;
                    bottom: 0;
                }
            }

            &:focus {
                background-color: $gray-200;
            }
    
    
            &:hover {
                background-color: $gray-200;
            }
        }
    }

    
}
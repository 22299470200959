//──── ERPL FORM VALIDATE ────────────────────────────────────────────────────────────────
.erpl_form-validation,
.erpl_form-validation-custom {

	.form-control.valid {
		border-color: $success;

		+.input-group-append {
			.btn {
				border-color: $success
			}
		}
	}

	.form-control.valid:focus {
		box-shadow: 0 0 0 $btn-focus-width rgba($success, .25)
	}

	label.valid {
		color: $success;
		width: 100%;
		margin-top: 0.25rem;
		font-size: 85.71429%;
	}


	.form-control.error {
		border-color: $danger !important;

		+.input-group-append {
			.btn {
				border-color: $danger
			}
		}
	}

	.form-control.error:focus {
		box-shadow: 0 0 0 $btn-focus-width rgba($danger, .25)
	}

	label.error {
		color: $danger;
		width: 100%;
		margin-top: 0.25rem;
		font-size: 85.71429%;
	}



	// Pour chaque couleur du thème, lorsqu'elle est utilisée en tant que fond, adapter les couleurs du textes et optimiser le contrast. 
	@each $bgColorName,
	$bgColorValue in $theme-colors {
		& {
			$wai-color-success: wai-color($success, $bgColorValue);
			$wai-color-error: wai-color($danger, $bgColorValue);

			@if ($wai-color-success !=$success) {
				&.bg-#{$bgColorName} {
					label.valid {
						color: $wai-color-success;

					}

				}
			}

			@if ($wai-color-error !=$danger) {
				&.bg-#{$bgColorName} {
					label.error {
						color: $wai-color-error;
					}

				}
			}
		}



	}

}

.error-box label.error {
	background: $danger;
	color: $white;
	z-index: 951;
	padding: .5rem 1rem;
	margin: 0;
	position: relative;
	border-bottom-left-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
	top: -3px;
}



.erpl_dropdown, .erpl_dropdown-custom {
	&.error .value,
	&.error .input-group-append .input-group-icon,
	&.error .erpl_dropdown-content>div {
		border-color: $danger !important
	}
}
.erpl_accordion ul .erpl_accordion-item.error {
	border: 1px solid $danger;
	position: relative;
	z-index: 9;
}
 

// -- Placeholder : color

input::placeholder {
	color: $gray-500 !important;

}
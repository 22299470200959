
   
@mixin animateLinks($link-color, $link-hover-color, $reverse-link-color: $gray-900 ) {

    .a-i a,
    .a-i .a-i-none .a-i a {
        @if ($darkMode) {
            color: $gray-200;
   
        } @else {
            color: $reverse-link-color;
   
        }
    };

    .a-i a:hover,
    .a-i .a-i-none .a-i a:hover {
        color: $link-color;
    }

    .a-i .a-i-none a,
    .a-i .a-i-none .a-i .a-i-none a {
        color: $link-color;
    }

    .a-i .a-i-none a:hover,
    .a-i .a-i-none .a-i .a-i-none a:hover {
        color: $link-hover-color;
    }


}


 //──── Animation des liens ───────────────────────────────────────────────────────────────


 @include animateLinks($link-color, $link-hover-color);


// Pour chaque couleur du thème, lorsqu'elle est utilisée en tant que fond, adapter les couleurs du textes et optimiser le contrast. 
@each $bgColorName, $bgColorValue in $bg-colors {
    $wai-color: wai-color($link-color, $bgColorValue);

    @if ($wai-color != $link-color) {
      .bg-#{$bgColorName} {
        $wai-color-hover: darken($wai-color, 15%);
        $reverse-link-color: wai-color($gray-900, $bgColorValue);

        @include animateLinks($wai-color, $wai-color-hover, $reverse-link-color);


      }

    }

  }


 [data-link-mode="true"] a:first-of-type:after {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: "";
    z-index: 9;
}


p {
    a:not(.btn) {
        text-decoration: underline;
    }
    a:focus:not(.btn) {
        text-decoration: underline;
        box-shadow: 0 0 0 2px #295ec5;
        color: $blue;
    }
    a:hover:not(.btn) {
        @if ($darkMode) {
            color: darken($primary, 15%);
        } @else { 
            color: #153d8c;
         }
        text-decoration: underline;
    }
    a:visited:not(.btn) {
        text-decoration: underline;
        @if ($darkMode) {
            color: #da4ada;
        } @else { 
            color: #993499;
         }
    }
}

p.a-i a:hover {
    color: $primary;
}

//──── Séparateurs ───────────────────────────────────────────────────────────────────────

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 1px;
}

.separator:after {
  border-color: #d1d3d4;
  border-top-style: solid;
  border-width: 1px;
  content: "";
  width: 100%;
  z-index: 0;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;

}

.separator-dotted:after {
  border-top-style: dotted;
}

.separator-dashed:after {
  border-top-style: dashed;
}

.separator-2x:after {
  border-width: 2px;
}

.separator > * {
  z-index: 1;
  display: flex;
}

.separator > .flags i {
  margin: 0 4px 0 0;
}

.separator > .btn {
	padding: .875rem 1.875rem 
}

.separator > .flags {
  max-width: 500px;
  flex-wrap: wrap;
  justify-content: center
}

.separator > .flags i:nth-child(n+31) {
  display: none;
}

//──── ERPL HEMICYCLE ────────────────────────────────────────────────────────────────────
.erpl_hemicycle svg circle {
	fill: $gray-300;
}

.erpl_hemicycle svg circle.disabled {
	fill: $gray-300 !important;
}

.erpl_hemicycle svg circle[data-value] {
	cursor: pointer;
}

.erpl_hemicycle .erpl_hemicycle-groups ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

.erpl_hemicycle .erpl_hemicycle-groups ul li {
	border-bottom: 1px dotted $border-color
}

.erpl_hemicycle .erpl_hemicycle-groups ul li .erpl_switch-btn {
	margin-right: 10px;
}

.erpl_hemicycle .erpl_hemicycle-groups ul li label {
	margin: 0;
	padding: 10px 0 ;
	display: block;
	font-family: $font-family-serif;
	font-size: 1rem
}


.erpl_hemicycle .erpl_hemicycle-groups .bullet {
	height: 12px;
	width: 12px;
	display: inline-block;
	border-radius: 50%;
	margin-right: 5px;
}

.erpl_hemicycle-map-canvas svg {
	max-width: 100%;
	height: auto;
}

.erpl_hemicycle-map-canvas-tooltip {
	position: fixed; 
	@if ($darkMode) {
		background: $gray-900;
	} @else { 
		background: $white;
	 }
	z-index: $zindex-tooltip;
	width: 360px;
}

.erpl_hemicycle-map-canvas-tooltip .erpl_hemicycle-map-canvas-tooltip-mep-picture,
.erpl_hemicycle-modal .erpl_hemicycle-map-canvas-modal-mep-picture {
	width: 100%;
	height: auto;
}

//.erpl_hemicycle-mep-info-group-logo {
//	background-image: url('../../widgets/hemicycle/img/political_groups_16x16.png');
//	width: 16px;
//	height: 16px;
//	display: inline-block;
//}
//
//.erpl_hemicycle-mep-info-group-logo.enf {
//	background-position: -32px -32px
//}
//
//.erpl_hemicycle-mep-info-group-logo.s_d {
//	background-position: -16px -32px
//}
//
//.erpl_hemicycle-mep-info-group-logo.ni {
//	background-position: 0px -32px
//}
//
//.erpl_hemicycle-mep-info-group-logo.gue_ngl {
//	background-position: -32px -16px
//}
//
//.erpl_hemicycle-mep-info-group-logo.verts_ale {
//	background-position: -16px -17px
//}
//
//.erpl_hemicycle-mep-info-group-logo.ppe {
//	background-position: 0px -17px
//}
//
//
//.erpl_hemicycle-mep-info-group-logo.alde {
//	background-position: 0px 0px
//}
//
//.erpl_hemicycle-mep-info-group-logo.ecr {
//	background-position: -16px 0px
//}
//
//.erpl_hemicycle-mep-info-group-logo.efdd {
//	background-position: -32px 0px
//}
//
///* RENEW */
//.erpl_hemicycle-mep-info-group-logo.renew{
//	background-position: 0px -48px;
//}
//
///* ID */
//.erpl_hemicycle-mep-info-group-logo.i-d{
//	background-position: -16px -48px;
//}


.erpl_hemicycle-map-canvas-tooltip .seat,
.erpl_hemicycle-modal .seat {
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAxMiAxNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIgMTU7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBzdHlsZT0iZmlsbDojOTk5OTk5OyIgcG9pbnRzPSIwLDAgMSwzIDEsMTUgMiwxNSAyLDExIDMsMTAgOSwxMCAxMCwxMSAxMCwxNSAxMSwxNSAxMSw5IDEyLDkgMTIsOCAzLDggMiwzICIvPjwvc3ZnPg==);
	width: 12px;
	height: 15px;
	display: inline-block;
	margin-right: 5px;
}

.erpl_hemicycle-modal [data-dismiss="modal"]{
	cursor: pointer;
}
@media (min-width: map-get($map: $grid-breakpoints, $key: lg)){
	.erpl_hemicycle .erpl_hemicycle-groups ul {

		column-count: 2
	}
	.erpl_hemicycle .erpl_hemicycle-map-canvas {
		max-width: 750px;
		margin: auto;
	}
}


.erpl_hemicycle .statsCircle-container {
	margin-left: 20px;
    height: 70px;
    width: 70px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
	@if ($darkMode) {
		background: $gray-900;
	} @else { 
		background: $gray-200;
	 }
}

.erpl_hemicycle .statsCircle {
	position: absolute;
}

.erpl_hemicycle .statsCircle-container span.value {
    font-weight: bold;
}


.erpl_hemicycle .statsCircle-container canvas {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}

.erpl_hemicycle-group-popover {
    display: none;
    position: absolute;
    @if ($darkMode) {
		background: $gray-900;
	} @else { 
		background: $white;
	 }
    z-index: $zindex-popover;
    top: calc(-100% - 50px);
    border: 1px solid $border-color;
    padding: 10px 20px;
    box-sizing: border-box;
    border-radius: 5px;
}

.erpl_hemicycle-group-popover.active {
    display: block;
}
.erpl_hemicycle-group-popover .icon {
	height: 0;
}
.erpl_hemicycle-group-popover .icon:after {
	display: block;
	height: 10px;
	width: 10px;
	@if ($darkMode) {
		background: $gray-900;
	} @else { 
		background: $white;
	 }
	content:"";
	position: relative;
	margin: auto;
	border-left: 1px solid #d1d3d4;
	border-bottom: 1px solid #d1d3d4;
	transform: rotate(-45deg);
	top: 5px;
	bottom: 0;
	box-sizing: border-box;
}
//──── ERPL Accordion ────────────────────────────────────────────────────────────────────

.erpl_accordion {


	.erpl_accordion-title {
		background: $gray-200;
		padding: 1.25rem;
		border-top: 1px solid $border-color;
		border-left: 1px solid $border-color;
		border-right: 1px solid $border-color;
		margin: 0;
	}

	>ul {
		list-style: none;
		padding: 0;

		//ITEM
		>li.erpl_accordion-item {
			border: 1px solid $border-color;

			&:not(:first-child) {
				margin-top: -1px;
			}

			.erpl_accordion-item-title {
				font-weight: 700;
				display: flex;
				justify-content: space-between;
				align-items: center;
				position: relative;

				@if ($darkMode) {
					background: transparent;
				}

				@else {
					background: $gray-200;
				}

				>*:first-child {
					padding: 1.25rem;
					flex: 1 1 auto
				}


				.erpl_accordion-item-icon {
					border-radius: 50%;
					border: 1px solid $primary;
					height: 22px;
					width: 22px;
					min-width: 22px !important;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 1.25rem;
				}

				&[aria-expanded="true"] {
					@if ($darkMode) {
						background: $gray-900;
					}

					@else {
						background: $white;
					}

					&:after {
						border-bottom: 1px solid $border-color;
						content: "";
						height: 0;
						position: absolute;
						left: 1.25rem;
						bottom: 0;
						right: 1.25rem;
					}
				}
			}

			button:hover.erpl_accordion-item-title {
				color: $primary;
			}

			button:focus.erpl_accordion-item-title, a:focus.erpl_accordion-item-title {	
				outline-offset: -3px;
				@if ($darkMode) {
					outline: 3px dotted $white;
				}
				@else {
					outline: 3px dotted $gray-700;
				}
			}
			button.erpl_accordion-item-title {
				font-weight: 700;
				text-align: left;
				cursor: pointer;
				border: none;
				width: 100%;

				@if ($darkMode) {
					color: $white
				}
			}

			&[data-selected="true"] .erpl_accordion-item-title:not([aria-expanded="true"]):before {
				border-bottom: 1px solid $border-color;
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 4px;
				top: 0;
				background: $primary;
			}

			.erpl_accordion-item-content {
				>div {
					padding: map_get($spacers, 2);

					@if ($darkMode) {
						background: $gray-900
					}

					>.erpl_links-list.erpl_links-list-nav {
						margin-top: - map_get($spacers, 2);
						margin-bottom: - map_get($spacers, 2);

					}
				}
			}
		}

	}

	&[data-theme="white"]>ul>li.erpl_accordion-item .erpl_accordion-item-title {
		background: $white;
	}


	&[data-max-height="true"] {
		max-height: 50vh;
		overflow-y: auto;
		overflow-x: hidden
	}



}


/* Comportement d'une liste de liens dans erpl_accordion et erpl_side-navigation */

.erpl_accordion ul>li.erpl_accordion-item .erpl_links-list a,
.erpl_side-navigation .erpl_links-list a,
.erpl_side-navigation [class*="erpl_title"] a {
	display: flex;
}
.erpl_accordion ul>li.erpl_accordion-item .erpl_links-list a:focus,
.erpl_side-navigation .erpl_links-list a:focus,
.erpl_side-navigation [class*="erpl_title"] a:focus {
	z-index: 1;
	position: relative;
	outline-offset: 1rem;
}

.erpl_side-navigation [class*="erpl_title"] a:focus {
	@if ($darkMode) {
		outline: 3px dotted $white;
	}
	@else {
		outline: 3px dotted $gray-600;
	}
}

.erpl_accordion ul>li.erpl_accordion-item .erpl_links-list a span,
.erpl_side-navigation .erpl_links-list a span,
.erpl_side-navigation [class*="erpl_title"] a span {
	flex: 1 0 auto;
	max-width: 100%;
}

.erpl_side-navigation [class*="erpl_title"],
.erpl_side-navigation .erpl_links-list ul li {
	border-bottom: 1px solid $border-color;
}

.erpl_side-navigation .erpl_links-list ul li:first-child {
	padding-top: 1rem;
}

.erpl_side-navigation .erpl_links-list ul li:last-child {
	padding-bottom: 1rem;
}

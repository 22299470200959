//──── ERPL Breadcrumb ───────────────────────────────────────────────────────────────────────


.breadcrumb .breadcrumb-item a:focus {

	@if ($darkMode) {
		outline: 3px dotted $white;
	} @else {
		outline: 3px dotted $gray-600;
	}
	
}


//──── ERPL Tree Select ──────────────────────────────────────────────────────────────────
.erpl_tree  {
	position: relative;

	.erpl_tree-field {

		&:hover  {
			cursor: pointer;
		}	
		@if ($darkMode) {
			.erpl_tree-reset {
				background: transparent;
			}
		}
	}

	&[data-open="true"] .erpl_tree-content {
		display: block;
	}

	&[data-position="absolute"] .erpl_tree-content  {
		position: absolute;
		left: 0;
		right: 0;
		@if ($darkMode) {
			background: $gray-900;
		} @else { 
			background: $white;
		 }
		z-index: 998;
	}

	&[data-show-icon="false"] ul li .erpl_tree-icon-status {
		display: none !important;
	}

	.erpl_tree-content {
		margin-top: -1px
	}

	

	&[data-expanded="true"] {
		.erpl_tree-field {
			.form-control,
			.input-group-append .input-group-icon {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				border-color: $primary;


				.es_icon {
					transform: scaleY(-1);
				}
			}
		}
	}

	ul {
		list-style: none;
		padding: 0;


		> li {
			border-bottom: 2px dotted $border-color;
			padding: 10px 0 10px 10px;

			&[tabindex]:focus {
				outline: none;
				background: $gray-100
			}

			&:last-child {
				border-bottom: none;
			}

			&[tabindex]:focus {
				outline: none;
				@if ($darkMode) {
					background: $gray-800
				} @else { 
					background: $gray-100
				 }
			}
		}


		li {
			&.active > .erpl_tree-item {
				color: $primary; 
			}

			&.selected > .erpl_tree-item {
				color: $success; 
			}

			&:not(.selected) > .erpl_tree-item.highlight {
				font-style: italic;
				color: $warning
			}

			&[data-erpl-tree-selectable="false"][data-has-children="false"] > .erpl_tree-item.highlight {
				opacity: .8
			}

			.erpl_tree-icon-status {
				float: right;
				border: 1px solid $border-color;
				width: 17px;
				height: 17px; 
				border-radius: 50%;
				margin-right: 5px;
				margin-left: 5px;
			}


			ul {
				border-left: 1px solid $border-color;
				margin-left: 4px;
				padding-left: 10px;
				padding-top: 10px;
				padding-bottom: 10px;
				display: none;

				li {
					border-bottom: 1px dotted $border-color;
				}
			}


			&[data-has-children="false"][data-erpl-tree-selectable="false"] > .erpl_tree-item {
				color: $gray-500; 
			}


		}
	}
	
}





.erpl_tree ul li[data-has-children="true"] > .erpl_tree-item,
.erpl_tree ul li[data-erpl-tree-selectable="true"] > .erpl_tree-item {
	cursor: pointer;
}

// FIX IE - Clear button
.erpl_tree .erpl_tree-search input::-ms-clear {
    display: none;
}
//──── ERPL TAGS ─────────────────────────────────────────────────────────────────────────
.erpl_tags .btn-default {
		
	&:hover {
		border-color: $primary;
		background: $primary; 
		@if ($darkMode) {
			color: $black
		} @else { 
			color: $white
		}

		.es_icon {
			@if ($darkMode) {
				color: $black
			} @else { 
				color: $white
			}
		}

	}

	.es_icon {

		color: $icon-light;
		&.es_icon-tag {
			vertical-align: middle;
		}
	}

}


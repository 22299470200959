//──── ERPL CONTACT CARD ─────────────────────────────────────────────────────────────────

.erpl_contact-card[data-ellipsis="false"] {


    .erpl_contact-card-list {
        overflow: auto;

    }
    .erpl_contact-card-list ul,
    .erpl_contact-card-list a,
    .erpl_contact-card-list a > div {
        overflow: visible;
    }

}   

.erpl_contact-card .erpl_contact-card-list {
    list-style-type: none;
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

   
    ul {
        list-style-type: none;
        padding: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;

        li {
            display: flex;
            align-items: center;
            position: relative;
            line-height: normal;

            a {
                overflow: hidden;
                width: 100%;

                & > div {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    display: block;
                }
            }
            a:focus {     	
            	width: auto;
            	margin: 4px 0px 4px 0;
                @if ($darkMode) {
                    outline: 3px dotted $white;       
                }
                @else {
                    outline: 3px dotted $gray-600;
                }   
            }
            .es_icon {
                min-width: 20px;
                margin-right: 5px;
            }
        }
    }
}






//──── ERPL FORM STEPS ───────────────────────────────────────────────────────────────────

.erpl_form-steps .erpl_form-steps-nav {
	display: flex;
	flex: 1 0 auto;
	justify-content: space-between;
	border: 1px solid $border-color;
	background: $gray-500;
	position: relative;
	padding: 0;
	list-style: none;
}

.erpl_form-steps .erpl_form-steps-nav .erpl_form-steps-nav-item {
	flex: 1 1 auto; // grow - shrink - basis
	padding: .5rem 2rem;
	background: $gray-500;
	position: relative;
	font-weight: bold;
	color: $white;
	min-width: 0;
	transition: background 1s, color .6s;
	width: 100%;
}

.erpl_form-steps[data-steps-nowrap=true] .erpl_form-steps-nav .erpl_form-steps-nav-item {
	height: 40px;
}

.erpl_form-steps .erpl_form-steps-nav .erpl_form-steps-nav-item span {
	display: block;
}
.erpl_form-steps[data-steps-nowrap=true] .erpl_form-steps-nav .erpl_form-steps-nav-item span {
	display: block;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap
}


// Hover sur les étapes suivantes directes
.erpl_form-steps .erpl_form-steps-nav .erpl_form-steps-nav-item:not([data-step-status="selected"]):hover {
	cursor: pointer;
	background: $white;
	color: $secondary
}

.erpl_form-steps .erpl_form-steps-nav .erpl_form-steps-nav-item:not([data-step-status="selected"]):hover:after {
    border-color: transparent transparent transparent $white;
}

.erpl_form-steps .erpl_form-steps-nav .erpl_form-steps-nav-item:first-child {
	padding: .5rem 1rem;
}


.erpl_form-steps .erpl_form-steps-nav .erpl_form-steps-nav-item:before,
.erpl_form-steps .erpl_form-steps-nav .erpl_form-steps-nav-item:after {
	display: block;
    content: "";
    height: 0;
    width: 0;
    background: transparent;
    position: absolute;
    margin: auto;
    border-style: solid;
    border-width: 20px 0px 20px 20px;
	top: 0;
	z-index: 1;
	transition: 1s
}

// surtriangle
.erpl_form-steps .erpl_form-steps-nav .erpl_form-steps-nav-item:after {
    right: -20px;
    border-color: transparent transparent transparent $gray-500;
}

// surtriangle selected
.erpl_form-steps .erpl_form-steps-nav .erpl_form-steps-nav-item[data-step-status="selected"]:after {
    border-color: transparent transparent transparent $primary;
}


// surtriangle completed
.erpl_form-steps .erpl_form-steps-nav .erpl_form-steps-nav-item[data-step-status="completed"]:after {
    border-color: transparent transparent transparent $white;
}


// Subtriangle
.erpl_form-steps .erpl_form-steps-nav .erpl_form-steps-nav-item:before {
    right: -22px;
	border-color: transparent transparent transparent $white;
    border-width: 20px 0px 20px 21px;	
}

.erpl_form-steps .erpl_form-steps-nav .erpl_form-steps-nav-item[data-step-status="completed"]:before {
    border-color: transparent transparent transparent $border-color;
}


.erpl_form-steps .erpl_form-steps-nav .erpl_form-steps-nav-item:last-child:before,
.erpl_form-steps .erpl_form-steps-nav .erpl_form-steps-nav-item:last-child:after {
	display: none;
}



.erpl_form-steps .erpl_form-steps-nav .erpl_form-steps-nav-item[data-step-status="selected"]{
	background: $primary;
	@if ($darkMode) {
		color: $black; 
	} @else { 
		color: $white; 
	 }
}

.erpl_form-steps .erpl_form-steps-nav .erpl_form-steps-nav-item[data-step-status="completed"]{
	background: $white;
	color: $secondary; 
}
.erpl_form-steps .erpl_form-steps-nav .erpl_form-steps-nav-item[data-step-status="completed"]:hover{
	cursor: pointer;
}


.erpl_form-steps .erpl_form-steps-content .erpl_form-steps-item,
.erpl_form-steps .erpl_form-steps-footer .submit {
	display: none;
}

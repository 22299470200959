//──── glossary ────────────────────────────────────────────────────────────────

.erpl_glossary {
    
    .erpl_list-letters {
        padding: 0;

        .erpl_letter {
            display: inline-block;
            text-align: center;
            margin-right: 4px;
            margin-bottom: 8px;
            a:focus {
                outline: 0;
            }
            a:focus span{
                @if ($darkMode) {
                    outline: 3px dotted $white;
                }
                @else {
                    outline: 3px dotted $gray-600;
                }               
            }  
            span {
                border: 1px solid $gray-300;
                border-radius: 50%;
                font-size: 1.2em!important;
                font-weight: lighter;
                height: 35px;
                width: 35px;
                padding: 5px;
                display: flex;
                flex-flow: column wrap;

                @if ($darkMode) {
                    color: $white;
                } @else { 
                    color: $secondary;
                }

                &:hover:not(.noletter) {
                    background-color: $gray-300;
                    color: $gray-900;
                    cursor: pointer;
                }
            }

            &.checked span,
            input:checked + span {
                background-color: $primary!important;
                @if not ($darkMode) {
                    color: $white;

                    &:hover {
                        color: $white;
                    }
                } @else {
                    color: $black;

                }

                
            }


            .noletter,
            input:disabled + span {
                
                pointer-events: none;

                @if ($darkMode) {
                    background-color: $gray-800;
                    border-color: $gray-800;
                    color: $gray-400;
                } @else { 
                    background-color: $gray-300;
                    border-color: $gray-300;
                }
            }

        }
        
    }
    
}
//──── ERPL DOCUMENTS ────────────────────────────────────────────────────────────────────
.erpl_document {
    position: relative;
    .erpl_document-title a:focus span.t-item{
        @if ($darkMode) {
            outline: 3px dotted $white;
            color: $yellow;
        }
        @else {
            outline: 3px dotted $gray-600;
            color: $primary;
        }   
    }
    .erpl_document-title a:focus {
        outline: 0;
    }
    .erpl_document-subtitle-doc:focus span.t-y, .erpl_document-subtitle-pdf:focus span.t-y, .erpl_document-subtitle-reference:focus span.t-y{
        @if ($darkMode) {
            outline: 3px dotted $white;
        }
        @else {
            outline: 3px dotted $gray-600;
        }         
    }
    .erpl_document-subtitle-doc:focus, .erpl_document-subtitle-pdf:focus, .erpl_document-subtitle-reference:focus{
        outline: 0;
    }
   
    .erpl_document-subtitle {
        &[data-separator] {
            [class^="erpl_document-subtitle-"]:not(:first-child) {
                &:before {
                    color: $gray-900;
                }
            }
        }
        &[data-separator="-"] {
            [class^="erpl_document-subtitle-"]:not(:first-child) {
                &:before {
                    content: " - ";
                }
            }
        }
        &[data-separator="|"] {
            [class^="erpl_document-subtitle-"]:not(:first-child) {
                &:before {
                    content: " | ";
                    margin-right: 0.25rem;
                    margin-left: 0.25rem;
                    vertical-align: top;
                 
                  
            
                }
            }
        }
        &[data-separator=","] {
            [class^="erpl_document-subtitle-"]:not(:first-child) {
                &:before {
                    content: " , ";
                       margin-right: 0.15rem;
              
                }
            }
        }
    }
}



.erpl_format-doc, .erpl_format-pdf {
  @if ($darkMode) {
    color:$gray-300;

  } @else { 
    color:$secondary;

   }
}


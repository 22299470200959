//──── ERPL SIDE SEARCH ──────────────────────────────────────────────────────────────────

.erpl_side-search {

	.erpl_side-search-main-search {
		border-bottom: 1px solid $border-color;
		.erpl_side-search-main-search-value {
			display: none;
		}
	}
	
	.erpl_criteria {
		border-bottom: 1px solid $border-color;

		&:empty {
			display: none !important;
		}

	}

	.erpl_side-search-criteria {
		.erpl_side-search-criteria-body {
			.erpl_side-search-criteria-item {
				border-bottom: 1px solid $border-color;
			}
		}
	}

	.erpl_side-search-criteria {

		&.animated {
			// animation-fill-mode: inherit;
			width: 100%;
			display: block;
			position: fixed;
			clear: both;
			z-index: 999;
			box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.3);
			top: 0;
			bottom: 0;
			left: 0;
		}


		// Validation styles
		.erpl_side-search-criteria-item {
			.erpl_side-search-criteria-item-content {
				es-tree.error {
					&::part(button) {
						border: 1px solid $danger
					}
				}
			}
		}

	}


	&.mobile {

		.erpl_side-search-criteria {
			width: 100%;
			display: flex;
			flex-direction: column;
			position: fixed;
			z-index: $zindex-modal;
			box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.3);
			top: 0;
			bottom: 0;
			left: 0;
			transform: translate3d(-100%, 0, 0);
			opacity: 0;

			@if ($darkMode) {
				background: $gray-900;
			}

			@else {
				background: $white;

			}

			&.active {
				transform: translate3d(0, 0, 0);
				opacity: 1;
				transition: transform .5s
			}

			&.hidden {
				transition: .5s;
			}


			&.on-touch {
				opacity: 1;
				transition: transform 0s !important
			}


			// Header 
			.erpl_side-search-criteria-header {
				min-height: 40px;
				padding: 0 1.5rem;
				align-items: center;
				display: flex;

				@if not ($darkMode) {
					background: linear-gradient(180deg, #3d7ac1 0, #345e9c);
				}
			}

			// Body
			.erpl_side-search-criteria-body {

				padding: 1.5rem;
				flex-grow: 1;
				position: relative;
				overflow: auto;

				// Item
				.erpl_side-search-criteria-item {



					&.active {
						position: fixed;
						top: 40px;
						padding: 1.5rem;
						margin: 0 !important;
						border: none;
						left: 0;
						right: 0;
						bottom: 60px;

						@if ($darkMode) {
							background: $gray-900;
						}

						@else {
							background: $white;

						}

						z-index: 999;
						overflow: auto;
					}

					// Item Content
					.erpl_side-search-criteria-item-content {

						.erpl_dropdown-custom[data-max-height=auto] .erpl_dropdown-content>* {
							max-height: none;
						}


						// Form Check
						.form-check {
							padding-left: 0;

							input {
								display: none;
							}

							input+span.virtual-checkbox {
								content: "";
								display: block;
								background: $white;
								height: 21px;
								width: 21px;
								border: 1px solid $border-color;
								display: flex;
								align-items: center;
								justify-content: center
							}

							input:checked+span.virtual-checkbox:after {
								content: "";
								display: block;
								height: 11px;
								width: 11px;
								background: $primary;
							}

							input:disabled+span.virtual-checkbox:after {
								content: "";
								display: block;
								height: 11px;
								width: 11px;
								background: $border-color;
							}

							input[type="radio"]+span.virtual-checkbox {
								border-radius: 50%
							}

							input[type="radio"]+span.virtual-checkbox:after {
								border-radius: 50%
							}

							label {
								display: block;
								border-bottom: 1px dotted $border-color;
								padding: 1.25rem 0;
								display: flex;
								justify-content: space-between;
								align-items: center
							}


						}

						/* Une boîte de sélection personnalisée. */
						.es_select {

							&:not([data-paginate="true"]) {
								.es_select-body {
									ul.es_select-options {
										max-height: inherit;

									}
								}

							}

							.es_select-body {

								ul.es_select-options {

									.es_select-options-item {
										padding: 1.25rem;

										.es_select-options-item-icon {
											float: right;
											align-items: center;
											background: #fff;
											border: 1px solid #d1d3d4;
											content: "";
											display: block;
											display: flex;
											height: 21px;
											justify-content: center;
											width: 21px;
											position: relative;

											.es_icon {
												display: none;
											}
										}

										&[data-selected="true"] {
											.es_select-options-item-icon:after {
												background: $primary;
												content: "";
												display: block;
												height: 11px;
												width: 11px;
												top: auto;
												left: auto;
												right: auto;
												bottom: auto;
											}
										}

									}
								}
							}
						}

					}


				}

			}

			// Footer
			.erpl_side-search-criteria-footer {
				padding: 0 1.5rem;
				align-items: center;
				display: flex;
				border-top: 1px solid $border-color;
				min-height: 60px;
			}


		}


	}

	&:not(.mobile) {

		.erpl_side-search-criteria .erpl_side-search-criteria-body .erpl_side-search-criteria-item {
			.erpl_side-search-criteria-item-content .erpl_side-search-criteria-item-title {
				display: none;
			}

			>.erpl_side-search-criteria-item-title .erpl_side-search-criteria-item-value {
				display: none;
			}
		}



	}


}
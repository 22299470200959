
//──── ERPL Social links Vertical ────────────────────────────────────────────────────────

.erpl_social-share-vertical a {
	height: 35px;
	width: 35px;
	@if ($darkMode) {
		border: 1px solid $gray-200;
		color: $white;

	} @else { 
		border: 1px solid $gray-900;
		color: $icon-dark;

	 }
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background: $primary;

		@if ($darkMode) {
			color: $black;

		} @else { 
			color: $white;

		}
		
	}
	&:focus {
		@if ($darkMode) {
			background: $yellow;
			color: $black;
		}
		@else {
			background: $primary;
			color: $white;
		}
	}
}


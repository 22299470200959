:focus {
	outline-color: $primary;
}

//──── Input group btn ───────────────────────────────────────────────────────────────────

// Clear fonction
input.form-control {

	&.clearable + .input-group-append  {

			> *:not(.btn-clearable) {
				display: none;
			} 

			.btn-clearable {
				@if not ($darkMode) {
					background-color: $white;
				} @else {
					background-color: transparent;

				}
				cursor: pointer;

			} 

	
	}

	&:not(.clearable) + .input-group-append  {

	
			.btn-clearable {
				display: none;
			} 
	}
}



// Fix Bootstrap valid/invalid message
.input-group.is-invalid ~ {
	.invalid-feedback {
		display: block;
	}
  }
  
  .input-group.is-valid ~ {
	.valid-feedback {
		display: block;
	}
  }
  
  
//──── Font familly ──────────────────────────────────────────────────────────────────────
.font-familly-sans-serif {
	font-family: $font-family-sans-serif
}
.font-familly-serif {
	font-family: $font-family-serif
}



//──── List group ────────────────────────────────────────────────────────────────────────
.list-group-item:first-child {
    z-index: 2;
}

.list-group-item.active {
	color: inherit;
    z-index: inherit;
    background: inherit;
    border-color: rgba(0, 0, 0, 0.125);
}

.list-group-item.active:after {
	content: "";
    display: block;
    width: 4px;
    background: $primary;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
}

.list-group-item-action {
    color: $primary;
}

.list-group-item-action span {
    display: block;
}


//──── ERPL STICKY ───────────────────────────────────────────────────────────────────────
.position-sticky {
	top: 60px
}

//──── AGENDA ───────────────────────────────────────────────────────────────────────
.erpl_agenda-date {
	font-size: 1.2rem;
	font-family: Georgia, Garamond, serif;
	}

//──── SUBMENU ──────────────────────────────


header .erpl_menu ul > li > a > span, header .erpl_menu ul > li > div > span {
    margin-right: 7px;
}

header .erpl_menu ul ul {
    min-width: 240px;
}

header .erpl_menu ul > li > a > span.current-nav {
    height: 40px;
}

.border-xs-bottom{
	border-bottom:1px solid #d1d3d4!important;
}  
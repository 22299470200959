body > .es_datepicker-panel {
	box-shadow: $box-shadow;
}

input.es_datepicker {
	&[data-read-only] {
		background-color: $input-disabled-bg;

	}
}

.es_datepicker-panel {
	border: 1px solid $border-color;
	min-width: 360px;

	@if ($darkMode) {
		background-color: $gray-900;
		color: $white;
	} @else { 
		background-color: $white;
	}

	.es_datepicker-panel-header {
		.es_datepicker-panel-header-nav {
			display: flex;
			padding: 1rem;

			> .es_datepicker-panel-header-nav-current {
				flex: 1 0 auto;
				margin: 0 .75rem;
			}

			> .es_datepicker-panel-header-nav-prev,
			> .es_datepicker-panel-header-nav-current,
			> .es_datepicker-panel-header-nav-next {
				border-color: $border-color;

				&:hover {
					border-color: $primary
				}

				@if ($darkMode) {
					background-color: $gray-900;
					color: $white;
				}
			}
			
		}
	}

	.es_datepicker-panel-body {
		.es_datepicker-panel-body-items {
			border-top: 1px solid $border-color;

			table {
				width: 100%;

					@if ($darkMode) {
						background: $gray-900;

					} @else { 
						background: $white;
					}

				thead {

					@if ($darkMode) {
						background: $gray-800;

					} @else { 
						background-color: $gray-200;
					}
				}

				th, td {
					padding: 0;
					border: 1px solid $gray-200;

					@if ($darkMode) {
						border: 1px solid $gray-800;

					} @else { 
						border: 1px solid $gray-200;

					}

				}
			}

			.es_datepicker-panel-body-item {
				vertical-align: middle;
				padding: .75rem;
				border: none;
				width: 100%;
				background: transparent;

				@if ($darkMode) {
					color: $white;
				}
			}


			@mixin selectableElement(){
				&:not(.disabled) {
					&:hover,&.hover {
						@if ($darkMode) {
							background: $gray-800;
						} @else { 
							background: $gray-200;
						}

						&.selected {
							background: $dark-primary;
						}
					}
				}

				&.disabled, &.disabled.prev, &.disabled.next {
					

					@if ($darkMode) {
						background: $gray-900;
						color: $gray-600;
						
					} @else { 
						background: $white;
						color: $gray-300;
					}

				}

				&:not(.disabled) {
					&:hover {
						cursor: pointer;

						@if ($darkMode) {
							background: $gray-800;
						} @else { 
							background: $gray-200;
						}
					}

					&:focus {
						outline: 2px solid $primary;
						position: relative;

						@if ($darkMode) {
							background: darken($color: $primary, $amount: 40);

						} @else { 
							background: lighten($color: $primary, $amount: 40);

						}
					}
				}

				&.selected {
					background: $primary;

					@if ($darkMode) {
						color: $gray-900;

					} @else { 
						color: $white;

					}

					&:hover, &:focus {
						background: $dark-primary;
					}
				}

				&.prev, &.next {
					@if ($darkMode) {
						color: $gray-500;

					} @else { 
						color: $text-muted;
					}

					&.selected {
						background: $primary;

						@if ($darkMode) {
							color: $gray-900;

						} @else { 
							color: $white;

						}
	 
					}
				}
			}

			&.es_datepicker-panel-body-years {
				table {
					td, th {
						width: calc(100%/3);

					}
				}
				.year-item {
					height: 100px;
					@include selectableElement();
				}
			}
			&.es_datepicker-panel-body-months {
				table {
					td, th {
						width: calc(100%/4);

					}
				}
				.month-item {
					height: 100px;
					@include selectableElement();
				}
			}

			&.es_datepicker-panel-body-week {
				background: $gray-200;
				table {
					td, th {
						width: calc(100%/3);

					}
				}
			}

			&.es_datepicker-panel-body-days {
				table {
					td, th {
						text-align: right;
						width: calc(100%/7);

					}
				}
				.day-item {
					text-align: right;
					@include selectableElement();
				}
			}

		}

	}
}
//──── LoadingBox ────────────────────────────────────────────────────────────────────────
.erpl_loading-box {
	position: fixed; 
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: $zindex-loading;
}





//──── Loading Box ───────────────────────────────────────────────────────────────────────
body[data-loading-box="true"] {
	overflow: hidden;

	&:after {
		@if ($darkMode) {
			background: rgba(0,0,0,0.9);
		} @else { 
			background: rgba(255,255,255,0.9);
		 }
		content: "";
		left:0;
		right: 0;
		bottom: 0;
		top: 0;
		display: block;
		position: fixed;
		z-index: $zindex-loading - 1
	}
}


//──── ERPL columns ───────────────────────────────────────────────────────────────────────

// Nombre de col

.column-1 {
	column-count: 1;
}

.column-2 {
	column-count: 2;
}

.column-3 {
	column-count: 3;
}

.column-4 {
	column-count: 4;
}

.column-5 {
	column-count: 5;
}

.column-auto {
	column-count: auto;
}

// Gestion des col avec breakpoints


@include media-breakpoint-up(sm) {
	.column-sm-1 {
		column-count: 1 !important;
	}

	.column-sm-2 {
		column-count: 2 !important;
	}

	.column-sm-3 {
		column-count: 3 !important;
	}

	.column-sm-4 {
		column-count: 4 !important;
	}

	.column-sm-5 {
		column-count: 5 !important;
	}
}


@include media-breakpoint-up(md) {
	.column-md-1 {
		column-count: 1 !important;
	}

	.column-md-2 {
		column-count: 2 !important;
	}

	.column-md-3 {
		column-count: 3 !important;
	}

	.column-md-4 {
		column-count: 4 !important;
	}

	.column-md-5 {
		column-count: 5 !important;
	}
}

@include media-breakpoint-up(lg) {
	.column-lg-1 {
		column-count: 1 !important;
	}

	.column-lg-2 {
		column-count: 2 !important;
	}

	.column-lg-3 {
		column-count: 3 !important;
	}

	.column-lg-4 {
		column-count: 4 !important;
	}

	.column-lg-5 {
		column-count: 5 !important;
	}
}

@include media-breakpoint-up(xl) {
	.column-xl-1 {
		column-count: 1 !important;
	}

	.column-xl-2 {
		column-count: 2 !important;
	}

	.column-xl-3 {
		column-count: 3 !important;
	}

	.column-xl-4 {
		column-count: 4 !important;
	}

	.column-xl-5 {
		column-count: 5 !important;
	}
}

// Espacement entre les col

.column-gap {
	column-gap: $grid-gutter-width * 2;
}

// Gestion du séparateur entre les col (épaisseur, style, couleur)

.column-rule {
	column-rule: 1px solid $border-color;
}

// Etendue du contenu sur toutes les col

.column-span {
	column-span: all;
}

// stylelint-disable declaration-no-important

// Typography

@mixin text-emphasis-variant($parent, $color) {
  #{$parent} {
    color: $color !important;
  }
  a#{$parent} {
    @include hover-focus {
      color: darken($color, 10%) !important;
    }
  }

 
  // Pour chaque couleur du thème, lorsqu'elle est utilisée en tant que fond, adapter les couleurs du textes et optimiser le contrast. 
  @each $bgColorName, $bgColorValue in $bg-colors {
    $wai-color: wai-color($color, $bgColorValue);

    @if ($wai-color != $color) {
      .bg-#{$bgColorName} #{$parent} {
        color: $wai-color !important;
      }
  
      .bg-#{$bgColorName} a#{$parent} {
        @include hover-focus {
          color: darken($wai-color, 10%) !important;
        }
      }
    }

  }
}

//──── ERPL CRITERIA ────────────────────────────────────────────────────────────────────

.erpl_criteria .btn-default {

	@if ($darkMode) {
		color: $gray-900;
	} @else {
		color: $primary
	}
	white-space: normal;

	.es_icon {
		color: $gray-500;
	}

	&:hover {
		border-color: $primary;
		background: $primary; 
		@if ($darkMode) {
			.es_icon {
				color: $black;
			}
		} @else { 
			color: $white;
			.es_icon {
				color: $white;
			}
		}
	}
	&  > * {
		vertical-align: middle;
	
		
	}
}


//========================================================================================
/*                                                                                      *
 *                                    ERPL SLIDESHOW                                    *
 *                                                                                      */
//========================================================================================

.erpl_slideshow {
	background: $black;

	.erpl_slideshow-body {

		.erpl_slideshow-item {
			margin: 0;

			&:hover, &:focus {
				.erpl_image-frame span {
					transform: scale(1.2)
				}
			}


			.erpl_image-frame {
				overflow: hidden;
				
				span {
					transition: .35s;
				}

				

				.erpl_slideshow-item-info {
					position: absolute;
					bottom: 0;
					display: flex;
					align-items: flex-end;

					.btn {
						background: $black;
						color: $white;
						border-radius: 0;

						&:hover {
							background: $primary
						}
						&:focus {
							box-shadow: 0 0 0 $btn-focus-width rgba($dark-primary, .5);
							background: $dark-primary;

						}
					}

					figcaption {
						background: rgba(0,0,0,.7);
						color: $white;
						line-height: normal
					}

				}
			}



			.erpl_slideshow-item-nav {
				display: none;
			}
		}
	}


	.erpl_slideshow-footer, .erpl_slideshow-item-nav {
		padding: .8rem 1.5rem;

		.erpl_slideshow-control {
			height: 40px;
		}
		.btn {
			@if ($darkMode) {
				background: $white;
			} @else { 
				background: transparent;
				color: $white
			 }
			border: 2px solid $white;

			&:hover {
				background: $primary;
			}

			&:focus {
				box-shadow: 0 0 0 $btn-focus-width rgba($primary, .5);
				background: rgba($primary, .5);
			}

			&.prev, &.next {
				.es_icon {
					// transform: scale(1.7)
				}
			}

			&.active {
				@if ($darkMode) {
					background: $black;
					color: $white
				} @else { 
					background: $white;
					color: $black;
				 }
				
			}
		}
	}


	&[data-mode="thumb"] {
		.erpl_slideshow-body {
			display: flex; 
			flex-wrap: wrap;
			padding: 10%;
			.erpl_slideshow-item {
				width: 20%;


				.erpl_image-frame {
					border: 3px solid transparent;
					&:hover {
						border: 3px solid $primary
					}

					.erpl_slideshow-item-info {
						display: none;
					}
				}
				&:focus .erpl_image-frame {
					border: 3px solid $primary
				}
			}
			
		}

		&[data-items-row='6'] .erpl_slideshow-item { width: calc(100%/6); }
		&[data-items-row='4'] .erpl_slideshow-item { width: 25%; }
		&[data-items-row='3'] .erpl_slideshow-item { width: calc(100%/3); }
		&[data-items-row='2'] .erpl_slideshow-item { width: 50%; }
	}

	&[data-mode="list"] {
		.erpl_slideshow-body .erpl_slideshow-item {

			.erpl_image-frame {
				&  > img {
					display: none;
				}
				& span {
					position: relative;
				}

				&:hover span {
					transform: none;
				}
				
			}

			.erpl_slideshow-item-nav {
				display: block;
			}
		}


		.erpl_slideshow-footer {
			display: none;
		}
	}

	
}

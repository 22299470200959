//──── Boutons ───────────────────────────────────────────────────────────────────────────
.btn-default {
    color: $gray-700;
    background-color: $white;
    border-color: $gray-300;
    &:hover:not(.disabled),
    &.active {
        color: $gray-900;
        background-color: $gray-200;
        border-color: $gray-800;
    }
}

//──── btn success ────────────────────────────────────────────────────────────────
.btn.btn-success a {
    color: $white;
}

.btn.btn-success a:hover {
    color: $success;
}

.es_context-menu {
	&:before {
		background: transparent;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		content: "";
	}

	a {
		color: $white;
		text-decoration: none;
		width: 100%;
		padding:  $btn-padding-y $btn-padding-x;

		&:hover {
			color: $white
		}

		&:focus {
			outline: 3px dashed $gray-500;
			position: relative;
			z-index: 1;
		}
	}

	button {
		color: $white;
		background: transparent;
		border: none;
		cursor: pointer;
		width: 100%;
		padding:  $btn-padding-y $btn-padding-x;
		&:focus {
			outline: 3px dashed $gray-500;
			position: relative;
			z-index: 1;
		}

	}

	ul {
		background: $gray-800;
		color: $white;
		border-radius: $border-radius;
		box-shadow : -0.2rem 0.2rem 0.8rem -0.2rem rgba(0, 0, 0, .35);
		padding: 0;
		margin: 0;
		list-style: none;
	}

	> ul {


		li {
			background: $gray-800;
			position: relative;
			cursor: pointer;

			&:first-child {
				border-radius: $border-radius $border-radius 0 0;
			}

			&:last-child {
				border-radius: 0 0 $border-radius $border-radius;
			}

			&.active {
				background: $gray-900;
				> ul {
					display: block;
					z-index: 1;
				}
			}

			&:hover {
				background: $gray-900;
			}

			// Séparateur
			&:not(:last-child):after {
				content: "";
				height: 1px;
				background: $gray-600;
				position: absolute;
				left: $btn-padding-x;
				right: $btn-padding-x;
				bottom: 0
			}

			.icon {
				width: 1rem;
				height: 1rem;
				display: inline-block;
				display: flex;
				&.center {
					align-items: center;
					justify-content: center;
				}

				&.round {
					border-radius: 50%;
					background: $gray-900;
				}
			}

			ul {
				display: none;
				position: absolute;
				top: 0;
				background: $gray-900;

				&[data-direction="left"] {
					left: 96%;
				}

				&[data-direction="right"] {
					right: 96%;
				}

			}

			
		}
	}

}

// Specifics colors for assistants
$icon-assistant-light: #a2a1a2 !default;
$icon-assistant-dark: #323132 !default;
$icon-assistant-blue: #0082ba !default;
$icon-assistant-purple: #9b40c5 !default;
$icon-assistant-green: #75cc05 !default;
$icon-assistant-orange: #fc6e03 !default;
$icon-assistant-light-blue: #96ddf9 !default;

$assistant-colors : (
    "light": $icon-assistant-light,
    "dark": $icon-assistant-dark,
    "blue": $icon-assistant-blue,
    "purple": $icon-assistant-purple,
    "green": $icon-assistant-green,
    "orange": $icon-assistant-orange,
    "light-blue": $icon-assistant-light-blue,
);

@each $key, $color in $assistant-colors {
    .es_icon-assistant-#{$key} {
        color: $color;
    }
}
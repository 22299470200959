// Form validation style of es-tree custom web component
@mixin form-validation-tree($state, $color) {
    es-tree {
        .was-validated &[internals-#{$state}],
        .was-validated &:#{$state},
        .was-validated &.is-#{$state}
         {

            &::part(button),
            &.is-#{$state} {
              border-color: $color;
        
              &:focus {
                border-color: $color;
                box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
              }
        
              
            }

            ~ .#{$state}-feedback,
            ~ .#{$state}-tooltip {
            display: block;
            }
        }
      }
}

@include form-validation-tree("valid", $form-feedback-valid-color);
@include form-validation-tree("invalid", $form-feedback-invalid-color);

es-tree {
  min-height: 35px;
  display: block;
}
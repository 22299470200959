
//──── Spinner ───────────────────────────────────────────────────────────────────────────
.spinner {
  animation: rotation 0.5s steps(8, end) infinite;
}

@-webkit-keyframes rotation {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
	
}


@keyframes rotation {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
	
}
